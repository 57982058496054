<template>
  <main class="page__content--wider">
    <div class="page__head2">
      <div
        class="page__head__container align-items-center justify-content-between"
      >
        <div class="page__head__container">
          <div class="page__head__icon">
            <img src="assets/img/page-icon.svg" alt="" srcset="" />
          </div>
          <div class="page__head__text">
            <h1 class="page__head__title">Branches</h1>
            <p class="page__head__subtitle">
              List of all your branches created
            </p>
          </div>
        </div>
        <div class="page__head__actions">
          <div class="d-flex">
            <div class="align-self-center mx-2 dropdown">
              <button
                class="
                  button button--grey
                  text--capital text--small
                  d-flex
                  align-items-center
                "
                type="button"
                @click="toggleFilterModal"
              >
                <!--<span class="dropdown__icon--left">
                  <img src="../../../assets/img/filter.svg" alt="" srcset="" />
                </span>-->
                <span class="filter_text"> filter </span>
                <!--<span class="dropdown__icon">
                  <ion-icon name="caret-down-outline"></ion-icon>
                </span>-->
              </button>
              <!-- <b-dropdown
                :html="filter_by_label"
                no-caret
                class="
                  m-2
                  page__body__header__button
                  dropdown--button-grey
                  w-100
                "
                v-model="fetchBranches.sortColumn"
              >
                <b-dropdown-item
                  v-for="item in cats"
                  :key="item.value"
                  :value="item.value"
                  @click="filterByCategory(item.value, item.text)"
                  >{{ item.text }}</b-dropdown-item
                >
              </b-dropdown> -->
            </div>
            <div class="align-self-center mx-2 dropdown">
              <button
                class="
                  button button--grey
                  text--capital text--small
                  d-flex
                  align-items-center
                "
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                export
                <span class="dropdown__icon">
                  <ion-icon name="caret-down-outline"></ion-icon>
                </span>
              </button>
              <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <a
                  class="dropdown-item text--capital"
                  href="javascript:void(0)"
                  v-if="userCanExportToPDF"
                  @click="pdfDownload"
                  >PDF</a
                >
                <a
                  class="dropdown-item text--capital"
                  href="javascript:void(0)"
                  v-if="userCanExportToExcel"
                  @click="processDownload"
                  >excel</a
                >
                <!-- <a class="dropdown-item text--capital">excel</a> -->
                <!-- <download-excel
                  class="dropdown-item text--capital"
                  style="cursor: pointer"
                  :data="download"
                  :fields="json_fields"
                  name="branches.xls"
                >
                  excel
                </download-excel> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="display:flex; width:50%; font-size:14px" v-if="filterText">
      <div style="width:80%">
        {{ filterText }}
      </div>
      <div style="display:flex;width:10%">
        <span style="cursor:pointer;font-size:17px" @click="editFilter">
          <i style="padding-right:5px" class="bi bi-pencil-square"></i>
        </span>
        <span style="cursor:pointer;font-size:17px" @click="deleteFilter">
          <i style="padding-left:5px" class="bi bi-x-circle-fill"></i>
        </span>
      </div>
    </div>
    <div class="page__body">
      <table class="customtable w-100">
        <thead>
          <tr>
            <th
              scope="col"
              class="table__header__text table__text text--capital"
              style="width: 14.8%"
            >
              Branch name
            </th>
            <th
              scope="col"
              class="table__header__text table__text text--capital"
              style="width: 14%"
            >
              ID
            </th>
            <th
              scope="col"
              class="table__header__text table__text text--capital"
              style="width: 14.3%"
            >
              Branch email
            </th>
            <th
              scope="col"
              class="table__header__text table__text text--capital"
              style="width: 14.3%"
            >
              Creation date
            </th>
            <th
              scope="col"
              class="table__header__text table__text text--capital"
              style="width: 14.3%"
            >
              last modified date
            </th>
            <th
              scope="col"
              class="table__header__text table__text text--capital"
              style="width: 14%"
            >
              Branch state
            </th>
            <th
              scope="col"
              class="table__header__text table__text text--capital"
              style="width: 14.3%"
            >
              Branch Note
            </th>
          </tr>
        </thead>
        <tbody v-if="this.loading">
          <tr>
            <td colspan="12">
              <div class="d-flex align-items-center justify-content-center p5">
                <div
                  class="spinner-border"
                  style="width: 3rem; height: 3rem"
                  role="status"
                >
                  <span class="sr-only">Loading...</span>
                </div>
                <span
                  class="text--black text--600 text--medium d-inline-flex ml-4"
                  >Please wait</span
                >
              </div>
            </td>
          </tr>
        </tbody>
        <tbody v-else-if="this.allBranches.items">
          <tr v-for="(task, index) in this.allBranches.items" :key="index">
            <td>
              <div>
                <span
                  class="table__body__text table__text text--capital d-block"
                  style="word-wrap: anywhere"
                  >{{ task.branchName }}</span
                >
                <!-- <span class="table__body__text table__text text--capital d-block text--small">Jerryson ibe</span> -->
              </div>
            </td>
            <td>
              <div>
                <span
                  class="table__body__text table__text text--capital d-block"
                  style="word-wrap: anywhere"
                  >{{ task.branchId }}</span
                >
                <!-- <span class="table__body__text table__text text--capital d-block text--small">Jerryson ibe</span> -->
              </div>
            </td>
            <td>
              <span class="table__body__text table__text" style="text-transform: lowercase; word-wrap: anywhere">{{
                task.branchEmailaddress
              }}</span>
            </td>
            <td>
              <span class="table__body__text table__text text--capital" style="word-wrap: anywhere">{{
                task.creationDate | formatDate
              }}</span>
            </td>
            <td>
              <span class="table__body__text table__text text--capital" style="word-wrap: anywhere">{{
                task.lastModifiedDate | formatDate
              }}</span>
            </td>
            <td>
              <span class="table__body__text table__text text--capital" style="word-wrap: anywhere">{{
                task.branchState
              }}</span>
            </td>
            <td>
              <span class="table__body__text table__text text--capital" style="word-wrap: anywhere">{{
                task.branchNotes
              }}</span>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="12">
              <div
                class="
                  d-flex
                  flex-column
                  align-items-center
                  justify-content-center
                  p5
                "
              >
                <img src="@/assets/img/empty.svg" alt="delete" srcset="" />

                <span
                  class="d-block text--danger text--600 text--medium mt-3 mb-5"
                  >No data available</span
                >
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <!-- <Pagination
        :allItems="allBranches"
        :size="size"
      /> -->

      <div class="pagination">
        <div class="d-flex align-items-center justify-content-between w-100">
          <div class="text--capital text--regular pagination__text">
            showing
            <span class="d-inline-block text--primary mr-3">
              <label for="">
                <select
                  v-model.number="fetchBranches.pageSize"
                  class="select select--lg select--page"
                  @change="changePageSize(fetchBranches.pageSize)"
                >
                  <option
                    v-for="psize in pagesizes"
                    :key="psize.id"
                    :id="psize.id"
                    :selected="fetchBranches.pageSize"
                  >
                    {{ psize.size }}
                  </option>
                </select>
              </label>
            </span>
            <span class="d-inline-block text--primary">{{
              allBranches.currentPage
            }}</span>
            to
            <span class="d-inline-block text--primary">{{
              allBranches.totalPages
            }}</span>
            <!-- of
            <span>{{ allBranches.totalCount }}</span> -->
          </div>

          <div
            class="
              pagination__buttons
              d-flex
              align-items-center
              justify-content-between
            "
          >
            <button
              class="button button--sm text--regular"
              v-if="allBranches.hasPreviousPage"
              @click="changeCurrentPage('prev')"
            >
              <ion-icon name="chevron-back-outline"></ion-icon>
            </button>
            <button
              class="button text--regular button--sm ml-2"
              v-for="pages in allBranches.totalPages"
              :key="pages"
              :class="{
                'button--primary text--white': allBranches.pageIndex == pages || allBranches.currentPage === pages,
              }"
              @click="changeCurrentPage(pages)"
            >
              {{ pages }}
            </button>
            <button
              class="button text--regular button--sm ml-2"
              v-if="allBranches.hasNextPage"
              @click="changeCurrentPage('next')"
            >
              <ion-icon name="chevron-forward-outline"></ion-icon>
            </button>
          </div>
        </div>
      </div>

      <DeleteModal
      :show="!userCanViewBranches"
      :title="'Unauthorized'"
      @close="goToDashboard"
    >
      <template v-slot:modal-body>
        <div class="modal__content">
          <div class="modal__body">
            <h5 class="modal__head__title text--capital mb-3">
              Unauthorized
            </h5>
            <!-- <h5 class="text-danger">
              You don
            </h5> -->
            <div class="d-flex mt-5">

              <button
                data-dismiss="#confirmModal"
                class="
                  button
                  form__button form__button--lg
                  save-changes
                  fitrem
                "
                @click.prevent="goToDashboard"
              >
                Go to Dashboard
              </button>
            </div>
          </div>
        </div>
      </template>
      </DeleteModal>

      <!-- Alert notification -->
      <div class="toaster show">
        <div
          class="toaster__container"
          v-if="alert.show"
          v-bind:class="{
            'toaster--success': alert.status === 'success',
            'toaster--error': alert.status === 'fail',
            'toaster--warn': alert.status === 'warn',
          }"
        >
          <div class="text-right">
            <span class="fa fa-times" @click="alert.show = false"></span>
          </div>
          <div>
            <span class="d-block text--small text--600 text--white">{{
              alert.title
            }}</span>
            <span class="d-block text--tiny text--400 text--white">{{
              alert.description
            }}</span>
          </div>
        </div>
      </div>
    </div>

    <CreateFilter
      :show="showFilterModal"
      @close="toggleFilterModal"
      @send-activate-filter="sendFilter"
      @reload="getAllBranches"
      :filterEditValue="filterEdit"
      :showEditedValue="showEditedValue"
      @alertError="alertError"
      :filterHeaderName="'Branch'"
      filterCategory="branch"
    />

  </main>
</template>
<script>
import * as XLSX from "xlsx";
// import JsonExcel from "vue-json-excel";
import jsPDF from "jspdf";
import "jspdf-autotable";
import ApiService from "@/core/services/general.service";
import moment from "moment";
import { mapState } from "vuex";
import { Role } from "@/core/services/role.js";
import DeleteModal from "@/components/DeleteModal/_base-delete-modal.vue";

export default {
  name: "all_branches",
  components: {
    // Pagination: () => import(/* WebpackChunkName: "Pagination" */'@/components/UI/pagination.vue'),
    // downloadExcel: JsonExcel,
    DeleteModal,
    CreateFilter: () => 
      import(
        /* WebpackChunkName: "CreateFilter" */ "@/view/pages/deposit/filter.vue"
      )
  },
  data() {
    return {
      showFilterModal: false,
      fetchBranches: {
        pageIndex: 1,
        pageSize: 10,
        filterCategory: "",
        filters: [],
      },
      pagesizes: [
        {
          size: 10,
        },
        {
          size: 20,
        },
        {
          size: 50,
        },
      ],
      allBranches: {},
      loading: true,
      alert: {
        show: false,
        status: "",
        title: "Success",
        description: "Deposit Product has been created",
      },
      filter_by_label: "Filter <ion-icon name='caret-down-outline'/>",
      cats: [
        {
          value: "branchname",
          text: "Branch Name",
        },
        {
          value: "branchstate",
          text: "State",
        },
        {
          value: "date",
          text: "Creation Date",
        },
      ],
      json_fields: {
        "Branch Name": "name",
        "ID": "id",
        "Branch Email": "email",
        "Creation Date": "date",
        "Last Modified Date": "date_mod",
        "Branch State ": "state",
        "Branch Note": "note",
      },
      exportData: [],
      filterText: "",
      filterEdit: [],
      showEditedValue: 0,
      paging: "",
    };
  },
  methods: {
    goToDashboard() {
      this.$router.push({ path: '/dashboard' });
    },
    toggleFilterModal() {
      this.showFilterModal = !this.showFilterModal;
    },
    getAllBranches() {
      this.deleteFilter()
      this.loading = true;
      this.fetchBranches.filters = [];
      this.fetchBranches.filterCategory = "";
      ApiService.post(
        `Branch/filter-branch`, this.fetchBranches
      )
        .then((response) => {
          this.allBranches = response.data.data;
          this.exportData = response.data.data.items;
          this.paging = response.data.data;
          this.loading = false;
        })
        .catch((error) => {
          this.alertError(error.response.data.message);
        });
        if(!this.userCanViewBranches) return this.alertWarning('You do not have permission to view this page');
    },
    getFilterBranches() {
      this.loading = true;
      ApiService.post(
        `GeneralFilter/ApplyFilter`, this.fetchBranches
      )
        .then((response) => {
          this.allBranches = { ...response.data, items: response.data.data };
          this.paging = response.data
          this.alertSuccess("Successfully filtered branches");
          this.filterText = response.data.filterText.replace(/_/g, " ")
          this.filterEdit = response.data.filters;
          this.loading = false;
          this.getExportData()
        })
        .catch((error) => {
          this.alertError(error.response.data.message);
        });
    },
    getExportData() {
      if (this.paging.totalCount > 0) {
        ApiService.post(`GeneralFilter/ApplyFilter`,
          { 
            filters: this.fetchBranches.filters,
            filterCategory: this.fetchBranches.filterCategory,
            pageIndex: this.fetchBranches.pageIndex,
            pageSize: this.paging.totalCount,
          })
          .then((res) => {
            this.exportData = res.data.data;
          })
          .catch((e) => {
            const error = e.response.data.message;
            this.alertError(error);
          });
      }
    },
    deleteFilter() {
      this.filterText = "";
      this.filterEdit = []
    },
    editFilter() {
      this.showEditedValue = Math.random()
      this.toggleFilterModal();
    },
    sendFilter(value) {
      const filterArray = value.map(obj => {
        const payload = {};

        payload.filterFieldName = obj.filterName;
        payload.filterFieldOperator = obj.filterOp;
        if (obj.value1 === '' || obj.value1 === null) {
          payload.filterFieldValues = [obj.value];
          return payload;
        }
        payload.filterFieldValues = [obj.value, obj.value1];
        return payload;
      });
      this.fetchBranches.filters = filterArray;
      this.fetchBranches.pageIndex = 1;
      this.fetchBranches.pageSize = 10;
      this.fetchBranches.filterCategory = "branch";
      this.getFilterBranches();
    },
    processDownload() {
      let wb = XLSX.utils.book_new();

      wb.Props = {
        Title: "Branches List Download",
        Subject: "Branches List Excel Sheet",
        Author: "Prolean",
        createdDate: Date.now(),
      };

      wb.SheetNames.push("Branches");

      let sheetData = [[
        "Branch Name",
        "ID",
        "Branch Email",
        "Creation Date",
        "Last Modified Date",
        "Branch State",
        "Branch Note",
      ]];
      if (this.exportData) {
        this.exportData.map((item) => {
          let new_arr = [];
          new_arr.push(item.branchName);
          new_arr.push(item.branchId);
          new_arr.push(item.branchEmailaddress);
          new_arr.push(moment(item.creationDate).format("DD-MMM-YYYY"));
          new_arr.push(moment(item.lastModifiedDate).format("DD-MMM-YYYY"));
          new_arr.push(item.branchState);
          new_arr.push(item.branchNotes);

          sheetData.push(new_arr);
        });
      }

      let ws = XLSX.utils.aoa_to_sheet(sheetData);

      wb.Sheets["Branches"] = ws;

      let wbSheet = XLSX.write(wb, { bookType: "xlsx", type: "binary" });

      this.saveExcelSheet(this.convertBinaryToOctetStream(wbSheet));
    },
    convertBinaryToOctetStream(sheet) {
      let buf = new ArrayBuffer(sheet.length);
      let view = new Uint8Array(buf);

      for (let i = 0; i < sheet.length; i++) {
        view[i] = sheet.charCodeAt(i) & 0xff;
      }

      return buf;
    },
    saveExcelSheet(data) {
      const url = URL.createObjectURL(
        new Blob([data], { type: "application/vnd.ms-excel" })
      );

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "branches.xlsx");
      document.body.appendChild(link);
      link.click();
    },
    pdfDownload() {
      const columns = [
        { title: "Branch Name", dataKey: "name" },
        { title: "ID", dataKey: "id" },
        { title: "Branch Email", dataKey: "email" },
        { title: "Creation Date", dataKey: "date" },
        { title: "Last Modified Date", dataKey: "date_mod" },
        { title: "Branch State", dataKey: "state" },
        { title: "Branch Note", dataKey: "note" },
      ];

      let doc = new jsPDF("l", "pt", "a4");
      doc.setFontSize(6);
      doc.autoTable(columns, this.download);
      doc.save("branches.pdf");
    },
    alertSuccess(description) {
      this.alert.status = "success";
      this.alert.title = "Success";
      this.alert.description = description;
      this.alert.show = true;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },
    alertWarning(description) {
      this.alert.status = "warn";
      this.alert.title = "Warning";
      this.alert.description = description;
      this.alert.show = true;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },
    alertError(description) {
      this.alert.status = "fail";
      this.alert.title = "Error";
      this.alert.description = description;
      this.alert.show = true;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },
    changePageSize(pageNum) {
      this.fetchBranches.pageIndex = 1;
      this.fetchBranches.pageSize = pageNum;
      if(this.fetchBranches.filterCategory === "branch") {
        this.getFilterBranches();
      } else {
        this.getAllBranches();
      }
    },
    changeCurrentPage(pages = null) {
      if (pages === "next") {
        this.fetchBranches.pageIndex++;
        if(this.fetchBranches.filterCategory === "branch") {
          this.getFilterBranches();
        } else {
          this.getAllBranches();
        }
      } else if (pages === 'prev') {
        this.fetchBranches.pageIndex--;
        if(this.fetchBranches.filterCategory === "branch") {
          this.getFilterBranches();
        } else {
          this.getAllBranches();
        }
      } else if (pages !== null) {
        this.fetchBranches.pageIndex = pages;
        if(this.fetchBranches.filterCategory === "branch") {
          this.getFilterBranches();
        } else {
          this.getAllBranches();
        }
      }
    },
    // filterByCategory(id, name) {
    //   this.filter_by_label = name + " <ion-icon name='caret-down-outline'/>";
    //   this.fetchBranches.sortColumn = id;
    //   this.getFilterBranches();
    // },
  },
  mounted() {
    this.getAllBranches();
  },
  computed: {
    ...mapState({ 
      user: (state) => state.auth.user,
    }),
    download() {
      let data = [];
      if (this.exportData) {
        this.exportData.forEach((item) => {
          let new_obj = {};
          new_obj.name = item.branchName;
          new_obj.id = item.branchId;
          new_obj.email = item.branchEmailaddress;
          new_obj.date = moment(item.creationDate).format("DD-MMM-YYYY");
          new_obj.date_mod = moment(item.lastModifiedDate).format(
            "DD-MMM-YYYY"
          );
          new_obj.state = item.branchState;
          new_obj.note = item.branchNotes;

          data.push(new_obj);
        });
      }

      return data;
    },

    userCanExportToExcel() {
      const user = this.user.scopes.find(item => item.scopeName === Role.Documents);
      const { permissionsList } = user;
      return permissionsList.CanExportToExcel;
    },

    userCanExportToPDF() {
      const user = this.user.scopes.find(item => item.scopeName === Role.Documents);
      const { permissionsList } = user;
      return permissionsList.CanDownloadDocument;
    },

    userCanViewBranches() {
      const user = this.user.scopes.find(item => item.scopeName === Role.Branch);
      const { permissionsList } = user;
      return permissionsList.CanViewBranch;
    },
  },
};
</script>
<style scoped>
.has-checkbox .checkmark {
  top: -10px;
}
</style>
